<template>
  <div class="modal fade" id="modal_add_org">
    <div class="modal-dialog" style="max-width: 1100px">
      <div class="modal-content">
        <div class="modal-header bg--blue">
          <h5 class="modal-title">{{ $t('lbl_add_branch') }}</h5>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="bg-white rounded-2xl m-4 pb-10">
            <div class="grid grid-cols-3 gap-4">
              <div>
                <form class="flex flex-col items-center justify-center">
                  <img class="w-72 h-64 object-contain mb-3 mt-4" :src="thumb_file" alt="Logo org" />
                  <label for="file-upload">
                    <div
                      class="button-add text-white cursor-pointer flex items-center justify-center rounded-lg h-10 px-3 py-2 focus:outline-none"
                    >
                      <div>{{ $t('lbl_change_logo') }}</div>
                    </div>
                  </label>
                  <input
                    id="file-upload"
                    class="d-none"
                    name="upload_cont_img"
                    type="file"
                    accept="image/*"
                    @change="onSelectedThumbFile($event)"
                  />
                </form>
              </div>
              <div class="col-span-2 m-4">
                <div class="grid grid-cols-2 gap-4 mb-3">
                  <div>
                    <label
                      class="text-xl text-black font-medium ml-3 mb-2"
                    >{{ $t('lbl_governing_body') }}</label>
                    <input
                      class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                      :placeholder="$t('lbl_enter_governing_body')"
                      v-model="form.authority_in_charge"
                    />
                  </div>
                  <div>
                    <label class="text-xl text-black font-medium ml-3 mb-2 required">
                      {{
                      $t(type === ORG_OPTIONS.root_org ? "lbl_org_name" : "lbl_branch_name")
                      }}
                    </label>
                    <input
                      class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                      :placeholder="$t('lbl_enter_name')"
                      v-model="form.orgName"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-4 mb-3">
                  <div>
                    <label class="text-xl text-black font-medium ml-3 mb-2">{{ $t('lbl_hotline') }}</label>
                    <input
                      class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                      :placeholder="$t('lbl_enter_phone_number')"
                      v-model="form.contact_point"
                    />
                  </div>
                  <div>
                    <label
                      class="text-xl text-black font-medium ml-3 mb-2"
                    >{{ $t('lbl_director_president_name') }}</label>
                    <div
                      class="border-0 flex items-center text--dark rounded-xl text-xl h-12 cursor-not-allowed bg-blue-gray pl-3 focus:outline-none w-full"
                    >{{ form.ownerName }}</div>
                  </div>
                </div>

                <div>
                  <label
                    class="text-xl text-black font-medium ml-3 mb-2 required"
                  >{{ $t('lbl_address') }}</label>
                  <input
                    class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                    :placeholder="$t('lbl_address')"
                    v-model="form.address"
                  />
                </div>
              </div>
            </div>
            <div class="text-right mx-4">
              <button
                v-loading="isFetching"
                @click="handleSubmitOrg"
                class="button-add text-white rounded-lg h-10 w-24 px-3 py-2 border-0 focus:outline-none"
              >{{ $t('lbl_create') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import appUtils from '../utils/appUtils'
import { isNil } from 'lodash'

export const ORG_OPTIONS = {
  root_org: 1,
  sub_root: 2
}
const DEFAULT_FORM = {
  authority_in_charge: '',
  orgName: '',
  ownerName: '',
  contact_point: '',
  address: ''
}

export default {
  name: 'ModalAddOrg',
  props: ['type', 'org'],
  data () {
    return {
      appUtils,
      thumb_file: '',
      listOption: null,
      imgPut: '',
      form: {},
      ORG_OPTIONS,
      isFetching: false,
      dataOrgMember: []
    }
  },
  mounted () {
    this.getOrgMembersV2()
  },
  watch: {
    org () {
      this.thumb_file = appUtils.getImageURL(this.org.logo)
      this.form.authority_in_charge = this.org.authority_in_charge
      this.form.ownerName = this.org?.owner?.name || ''
    }
  },
  methods: {
    onSelectedThumbFile (event) {
      this.imgPut = event.target.files[0]

      this.thumb_file = URL.createObjectURL(event.target.files[0])
    },
    async handlePostOrg () {
      const self = this
      const params = {
        authority_in_charge: self.form.authority_in_charge,
        name: self.form.orgName,
        address: self.form.address,
        contact_point: self.form.contact_point,
        parent_id: self.org.id,
        owner_id: self.org.owner_id
      }
      if (self.imgPut) {
        params.logo = self.imgPut
      }
      const formData = new FormData()

      for (const key in params) {
        if (!isNil(params[key])) {
          formData.set(key, params[key])
        }
      }

      self.isFetching = true
      try {
        const response = await self.$rf
          .getRequest('DoctorRequest')
          .postSubOrganization(formData)
        self.form = {
          ...DEFAULT_FORM,
          ownerName: this.form?.ownerName,
          authority_in_charge: this.form?.authority_in_charge
        }
        self.$emit('onAddSubOrgSuccess')

        window.$('#modal_add_org').modal('hide')
        return response
      } catch (e) {
        self.$toast.open({
          message: this.$t('msg_have_error_when_create'),
          type: 'warning'
        })
        console.log(e)
      } finally {
        self.isFetching = false
      }
    },
    handleSubmitOrg () {
      if (!this.form.orgName || !this.form.address) {
        alert(this.$t('msg_you_need_to_fill_in_all_the_required_information'))
        return
      }
      this.handlePostOrg()
    },
    async getOrgMembersV2 () {
      try {
        const params = {
          orgId: this.$user?.members[0].org_id,
          status: 1,
          limit: 1000,
          page: 1
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getOrgMembersV2(params)

        this.dataOrgMember = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .button-add {
    background-color: #20419b;
  }
  .select-form {
    appearance: none;
  }

  .arrow-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .el-select-owner {
    input {
      font-size: 18px !important;
      color: #41464b !important;
      font-weight: 400 !important;
      background-color: transparent !important;
      padding: 0 !important;
    }

    input::placeholder {
      font-size: 20px;
      color: #868181;
      font-weight: 400;
    }
  }
}
</style>
