<template>
  <div class="px-5">
    <div class="text-black text-2xl mt-10">
      <div class="flex items-center justify-between">
        <div class="my-3 font-bold">{{ org.name }}</div>
      </div>
    </div>

    <div class="table-responsive">
      <div class="bg-white rounded-3xl p-3">
        <!-- Organization Info -->
        <div class="border p-3 rounded-xl mt-1 mb-4">
          <div class="row fs-17 text-black">
            <div class="col-10 mb-2">
              <span>{{`${$t('workspace_setting.lbl_org_name')}: `}}</span>
              <span class="font-bold">{{ org.name }}</span>
            </div>
            <div class="col-2 mb-2">
              <svg
                @click="$router.push({name:'OrganizationDetailUpdate',params:{id:orgId}})"
                class="float-right cursor-pointer"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6201 9.00044C16.4212 9.00044 16.2304 9.07946 16.0898 9.22011C15.9491 9.36076 15.8701 9.55153 15.8701 9.75044V14.2504C15.8701 14.4494 15.7911 14.6401 15.6504 14.7808C15.5098 14.9214 15.319 15.0004 15.1201 15.0004H4.62012C4.4212 15.0004 4.23044 14.9214 4.08979 14.7808C3.94913 14.6401 3.87012 14.4494 3.87012 14.2504V3.75044C3.87012 3.55153 3.94913 3.36076 4.08979 3.22011C4.23044 3.07946 4.4212 3.00044 4.62012 3.00044H9.12012C9.31903 3.00044 9.50979 2.92142 9.65045 2.78077C9.7911 2.64012 9.87012 2.44935 9.87012 2.25044C9.87012 2.05153 9.7911 1.86076 9.65045 1.72011C9.50979 1.57946 9.31903 1.50044 9.12012 1.50044H4.62012C4.02338 1.50044 3.45108 1.7375 3.02913 2.15945C2.60717 2.58141 2.37012 3.15371 2.37012 3.75044V14.2504C2.37012 14.8472 2.60717 15.4195 3.02913 15.8414C3.45108 16.2634 4.02338 16.5004 4.62012 16.5004H15.1201C15.7169 16.5004 16.2892 16.2634 16.7111 15.8414C17.1331 15.4195 17.3701 14.8472 17.3701 14.2504V9.75044C17.3701 9.55153 17.2911 9.36076 17.1504 9.22011C17.0098 9.07946 16.819 9.00044 16.6201 9.00044ZM5.37012 9.57044V12.7504C5.37012 12.9494 5.44914 13.1401 5.58979 13.2808C5.73044 13.4214 5.9212 13.5004 6.12012 13.5004H9.30012C9.39882 13.501 9.49667 13.4821 9.58805 13.4448C9.67942 13.4074 9.76254 13.3525 9.83262 13.2829L15.0226 8.08544L17.1526 6.00044C17.2229 5.93072 17.2787 5.84777 17.3168 5.75638C17.3549 5.66498 17.3745 5.56695 17.3745 5.46794C17.3745 5.36893 17.3549 5.2709 17.3168 5.17951C17.2787 5.08812 17.2229 5.00516 17.1526 4.93544L13.9726 1.71794C13.9029 1.64765 13.8199 1.59185 13.7285 1.55377C13.6372 1.5157 13.5391 1.49609 13.4401 1.49609C13.3411 1.49609 13.2431 1.5157 13.1517 1.55377C13.0603 1.59185 12.9773 1.64765 12.9076 1.71794L10.7926 3.84044L5.58762 9.03794C5.51811 9.10802 5.46311 9.19113 5.42579 9.28251C5.38847 9.37389 5.36955 9.47174 5.37012 9.57044ZM13.4401 3.30794L15.5626 5.43044L14.4976 6.49544L12.3751 4.37294L13.4401 3.30794ZM6.87012 9.87794L11.3176 5.43044L13.4401 7.55294L8.99262 12.0004H6.87012V9.87794Z"
                  fill="#03267A"
                />
              </svg>
            </div>

            <div class="col-6 mb-2">
              <span class>{{ $t('lbl_governing_body') }}: &nbsp;</span>
              <span class="font-bold">{{ org.authority_in_charge }}</span>
            </div>
            <div class="col-6 mb-2">
              <span class="fs-17">{{ $t('lbl_director_president') }}: &nbsp;</span>
              <span class="font-bold">{{ org.owner ? org.owner.name : '' }}</span>
            </div>
            <div class="col-6">
              <span>{{ $t('lbl_hotline') }}: &nbsp;</span>
              <span>{{ org.contact_point }}</span>
            </div>
            <div class="col-6">
              <span>{{ $t('lbl_address') }}: &nbsp;</span>
              <span>{{ org.address }}</span>
            </div>
          </div>
        </div>
        <!-- Organization Admin -->
        <div class="mb-4">
          <div class="fs-22 font-bold text-black mb-3">{{ $t('lbl_board_of_administrators') }}</div>

          <div class="flex gap-3 flex-wrap">
            <div v-for="item in adminMembers" @click="handleClickAdminMember(item)" :key="item.id">
              <img v-if="item && item.avatar" class="avatar_admin" :src="getAvatar(item)" />
              <div v-else class="no_avatar_admin">{{ getText(item) }}</div>
            </div>
            <div @click="handleShowModalAddEmployee(true)" class="button_add">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8333 9.16659H10.8333V4.16659C10.8333 3.94557 10.7455 3.73361 10.5892 3.57733C10.433 3.42105 10.221 3.33325 9.99998 3.33325C9.77897 3.33325 9.567 3.42105 9.41072 3.57733C9.25444 3.73361 9.16665 3.94557 9.16665 4.16659V9.16659H4.16665C3.94563 9.16659 3.73367 9.25438 3.57739 9.41066C3.42111 9.56694 3.33331 9.7789 3.33331 9.99992C3.33331 10.2209 3.42111 10.4329 3.57739 10.5892C3.73367 10.7455 3.94563 10.8333 4.16665 10.8333H9.16665V15.8333C9.16665 16.0543 9.25444 16.2662 9.41072 16.4225C9.567 16.5788 9.77897 16.6666 9.99998 16.6666C10.221 16.6666 10.433 16.5788 10.5892 16.4225C10.7455 16.2662 10.8333 16.0543 10.8333 15.8333V10.8333H15.8333C16.0543 10.8333 16.2663 10.7455 16.4226 10.5892C16.5788 10.4329 16.6666 10.2209 16.6666 9.99992C16.6666 9.7789 16.5788 9.56694 16.4226 9.41066C16.2663 9.25438 16.0543 9.16659 15.8333 9.16659Z"
                  fill="#20419b"
                />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <div
            class="fs-22 font-bold text-black mt-4 mb-3"
          >{{ $t('lbl_member_companies_branches') }}</div>
          <div
            class="flex items-start sm:items-center justify-start sm:justify-between flex-col sm:flex-row gap-2"
          >
            <div class="flex items-center border bg-white rounded-md border-grey-300 p-2 w-60">
              <IconSearch class="cursor-pointer" />
              <input
                v-model="valueSearch"
                @keypress.enter="()=> getSubOrg(orgId ,{ page: 1 })"
                class="border-0 txt-grey-500 ml-2 focus:outline-none w-full"
                :placeholder="$t('lbl_enter_member_companies_branches__director_president_name')"
              />
            </div>
            <div class="flex items-center">
              <ButtonHoDo
                :title="$t('lbl_add_member_companies_branches')"
                class-name="bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
                @click="onShowModalAddSubOrg(true)"
              />
            </div>
          </div>
          <div class="table-responsive">
            <div class>
              <div
                class="fs-20 fw-600 grid grid-cols-4 gap-4 p-6 border-bottom border-gray-700 mb-6 pb-3"
              >
                <div class="txt-grey-1000">{{ $t('lbl_member_companies_branches_name') }}</div>
                <div class="txt-grey-1000">{{ $t('lbl_director_president') }}</div>
                <div class="txt-grey-1000 text-center">{{ $t('lbl_total_workspace') }}</div>
                <div class="txt-grey-1000 text-center">{{ $t('lbl_total_staffs') }}</div>
              </div>
              <div>
                <div
                  v-for="(subOrg, index) in subOrgs"
                  :key="index"
                  class="cursor-pointer mb-2 radius-10 bg-blue-gray grid grid-cols-4 gap-4 p-6 fs-16 fw-400 txt-grey-900"
                  @click="()=>handleClickSubOrg(subOrg.id)"
                >
                  <div class="fw-700">
                    <div>{{ subOrg.suborg_name }}</div>
                    <div class="fs-14 fw-400 mt-1">{{ subOrg.address }}</div>
                  </div>
                  <div>
                    <div>{{subOrg.owner_name }}</div>
                  </div>
                  <div>
                    <div class="text-center">{{ subOrg.number_workspace }}</div>
                  </div>
                  <div>
                    <div class="text-center">{{ subOrg.number_member }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex mt-3 flex-col md:flex-row justify-between items-center gap-2">
              <div class="fs-16">{{ $t('fm_pagination',{countItems,totalItems:totalItems }) }}</div>
              <el-pagination
                background
                layout="prev, pager, next"
                :pager-count="5"
                :page-size="DEFAULT_SIZE_OF_PAGE"
                :total="totalItems"
                class="el-pagination-mdt"
                @next-click="handleChangePage"
                @prev-click="handleChangePage"
                @current-change="handleChangePage"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalAddOrg @onAddSubOrgSuccess="handleAddSuccess" :type="ORG_OPTIONS.sub_root" :org="org" />
    <ModalAddEmployee @reload="getOrg" :isAddAdmin="true" />
  </div>
</template>

  <script>
import { ButtonHoDo } from '@/components/Button'
import { IconSearch } from '@/components/Icon'
import { debounce } from 'lodash'
import appUtils from '../../utils/appUtils'
import ModalAddOrg, { ORG_OPTIONS } from '../../components/ModalAddOrg.vue'
import { DEFAULT_SIZE_OF_PAGE } from '../../utils/constants'
import ModalAddEmployee from './ModalAddEmployee.vue'

export default {
  name: 'OrganizationManagement',
  components: { ButtonHoDo, IconSearch, ModalAddOrg, ModalAddEmployee },
  data () {
    return {
      subOrgs: [],
      currentSubOrg: [],
      org: {},
      appUtils,
      orgId: 0,
      ORG_OPTIONS,
      valueSearch: '',
      totalItems: 0,
      DEFAULT_SIZE_OF_PAGE,
      page: 1,
      dataOrgMember: []
    }
  },
  mounted () {
    this.callAPI()
  },
  computed: {
    countItems () {
      return this.page * DEFAULT_SIZE_OF_PAGE > this.totalItems
        ? this.totalItems
        : this.page * DEFAULT_SIZE_OF_PAGE
    },
    adminMembers () {
      return this.dataOrgMember
        ? this.dataOrgMember.filter(
          item => item?.role === 1 || item?.role === 2
        )
        : []
    },
    linkPartner () {
      const token = localStorage.getItem('jwt_token')

      if (!token || !this.orgId) return ''

      if (process.env.NODE_ENV === 'development') {
        return `https://partner.dev.hodo.app/#/auto-login?token=${token}&org_id=${this.orgId}`
      } else {
        return `https://partner.hodo.app/#/auto-login?token=${token}&org_id=${this.orgId}`
      }
    }
  },
  watch: {
    $globalOrg () {
      this.callAPI()
    },
    subOrgs (data) {
      if (data) {
        this.currentSubOrg = data.slice(0, DEFAULT_SIZE_OF_PAGE)
      }
    },
    page (currPage) {
      this.currentSubOrg = this.subOrgs.slice(
        (currPage - 1) * DEFAULT_SIZE_OF_PAGE,
        currPage * DEFAULT_SIZE_OF_PAGE
      )
      this.getSubOrg(this.orgId)
    }
  },
  methods: {
    callAPI () {
      this.orgId = this.$globalOrg?.id
      this.getOrg(this.orgId)
      this.getSubOrg(this.orgId)
      this.getOrgMembersV2(this.orgId)
    },
    async getSubOrg (id, data) {
      if (!id) return

      const params = {
        page: this.page,
        limit: DEFAULT_SIZE_OF_PAGE,
        ...data
      }

      if (this.valueSearch) {
        params.q = this.valueSearch
      }

      try {
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getSubOrganization(id, params)

        this.subOrgs = res.data?.code !== 404 ? res.data?.data?.reverse() : []
        this.totalItems = res.data?.meta?.total || 0
      } catch (error) {
        console.log(error)
      }
    },
    async getOrg (id) {
      if (!id) return
      const res = await this.$rf.getRequest('DoctorRequest').getOrg(id)
      this.org = res.data
    },
    onShowModalAddSubOrg (show) {
      if (!this.checkRoleOwnerOrg(this.org || this.$user)) {
        alert(this.$t('msg_you_do_not_have_permission_to_add_a_Sub-Org'))
        return
      }
      window.$('#modal_add_org').modal(show ? 'show' : 'hide')
    },
    backOrg () {
      this.$router.push({
        path: '/'
      })
    },
    handleChangePage: debounce(function (newPage) {
      this.page = newPage
    }, 500),

    handleClickSubOrg (id) {
      this.$router.push({ name: 'OrganizationDetail', params: { id } })
    },
    getAvatar (item) {
      return appUtils.getImageURL(item?.avatar)
    },
    getText (item) {
      return item && item?.name ? item.name.charAt(0) : ''
    },
    handleShowModalAddEmployee (show) {
      window.$('#modal--add-employee').modal(show ? 'show' : 'hide')
    },
    handleAddSuccess () {
      this.getSubOrg(this.orgId)
      this.$toast.open({
        message: this.$t('msg_add_branches_success'),
        type: 'success'
      })
    },
    handleClickAdminMember (item) {
      if (!item?.doctor_id) return

      this.$router.push({
        name: 'DoctorProfile',
        params: { id: item.doctor_id }
      })
    },
    async getOrgMembersV2 (id) {
      if (!id) return
      try {
        const params = {
          orgId: id,
          status: 1,
          limit: 1000,
          page: 1
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getOrgMembersV2(params)

        this.dataOrgMember = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
::v-deep {
  .txt-grey-1000 {
    color: #52575c !important;
  }
  .el-pagination-mdt {
    padding: 0;

    & .number.active {
      background-color: #20419b !important;
    }
  }

  .avatar_admin {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border: 1px solid #eeeeee;
    border-radius: 50%;
    cursor: pointer;
  }

  .no_avatar_admin {
    width: 40px;
    height: 40px;
    color: white;
    background: #20419b;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border: 1px solid #eeeeee;
    border-radius: 50%;
    cursor: pointer;
  }

  .button_add {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #9b9999;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
